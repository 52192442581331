import React from 'react';

class Header extends React.Component {

  	render() {
    	return (
      		<div className='header'>
       			<h2>Cook3r sim</h2>
      		</div>
    	)
  	};
}

export default Header;