import React from 'react';

import { Button }  from '@material-ui/core';

//Change to real url
var targetUrl = "https://cook3r-backend.aimsites.nl/train_rl_optim";

class TrainModel extends React.Component{

  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      results: {
        // avg_reward: "2.0",
        // std_reward: "1.0",
        // model: "Testmodel.ppo"
      },
      model: {
        model_file: ".zip",
        config_file: ".pkl",
      }
    }

    this.showResults = this.showResults.bind(this);
    this.downloadResultFile = this.downloadResultFile.bind(this);
  }

  componentDidMount(){
    let fullSettings = {};
    fullSettings["env_settings"] = this.props.settings;
    fullSettings["personas"] = this.props.personas;
    fullSettings["goals"] = this.props.settings.goals;
    fullSettings["model"] = this.props.settings.model;
    fullSettings["n_envs"] = this.props.settings.n_envs;
    this.sendSettings(fullSettings);
  }

  async sendSettings(settings){
    console.log(settings);

    //Send settings to API
    const response = await fetch(targetUrl, {
      method: 'POST',
      body: JSON.stringify(settings),
    });
    const results = await response.json();
    this.setState({
      results: results
    });
    var model = this.state.model;
    this.state.model.model_file = this.state.results.location_model;
    this.state.model.config_file = this.state.results.location_config;
    this.setState({
      model: model
    });
    console.log(this.state.results);
  }

  downloadResultFile(){
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(this.state.model, null, "\t")
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "model.json";
    link.click();
 }

 showResults(){
    let content = [];
    content.push(<p>Resultaten:</p>);
    var results =  this.state.results;
    if (results.message){
      content.push(<p>{results.message}</p>);
    }else if(results.model){
      content.push(<p>Model: {results.location_model}</p>);
      // content.push(<p>STD reward: {results.std_reward}</p>);
      // content.push(<p>AVG reward: {results.avg_reward}</p>);
    }else{
      content.push(<p>...</p>);
    }
    
    return (<div class="resultsBody">{content}</div>);
  }

  render() {
    return (
      <div className="content">
        <div className="resultBox">
          <h3>Train model</h3>
          <div class="innerResultBox">
            {this.showResults()}
          </div>
          <Button variant="contained" color="primary" onClick={this.downloadResultFile}>
            Download model
          </Button>
        </div>
      </div>
    );
  }
}

export default TrainModel;
