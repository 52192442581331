import React from 'react';

import docs from '../20221129 COOK3RSIM_Documentatie.docx';

import { Button }  from '@material-ui/core';

class Footer extends React.Component {

  	render() {

		const downloadDocs = () => {
		    const element = document.createElement("a");
		    element.href = docs;
		    element.download = "COOK3RSIM_Documentatie.docx";
		    element.click();
		 };

    	return (
      		<div className='footer'>
      			<div className="footerInner">
	       			<p className="pHeader">Git</p>
	       			<a href="https://gitlab.com/han-aim/research/cook3r-sim" target="_blank"><Button variant="contained" color="primary">GitLab code</Button></a>
	       			<p className="pHeader">Documentatie</p>
	       			<Button variant="contained" color="primary" onClick={downloadDocs}>Download documentatie</Button>
	       			<p className="pHeader">Contact</p>
	       			<p>Liesbet.Rabbinge@han.nl</p>
	       			<p>Maya.Sappelli@han.nl</p>
       			</div>
      		</div>
    	)
  	};
}

export default Footer;