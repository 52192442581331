import React from 'react';

import { Button }  from '@material-ui/core';

class VideoBlock extends React.Component {

	constructor(props) {
	    super(props);

	    this.changeVideo = this.changeVideo.bind(this);
  	}

  	changeVideo(video, button){
  		for(var i = 0; i < document.getElementsByClassName("video").length; i++){
  			document.getElementsByClassName("video")[i].style.display = "none";
  		}
  		for(var j = 0; j < document.getElementsByTagName("iframe").length; j++){
  			document.getElementsByTagName("iframe")[j].src = document.getElementsByTagName("iframe")[j].src;
  			// document.getElementsByTagName("iframe")[j].currentTime = 0;
  		}
  		for(var i = 0; i < document.getElementsByClassName("choiceBoxVid").length; i++){
  			document.getElementsByClassName("choiceBoxVid")[i].style.backgroundColor = "black";
  		}
  		document.getElementsByClassName("choiceBoxVid")[button].style.backgroundColor = "#BF0052";
  		document.getElementById(video).style.display = "block";
  	}



  	render() {

    	return (
      		<div className='videoBlock'>
      			<div className="videoHeader">
      				<h2>Videos</h2>
	      			<div className="choiceBoxVid" style={{backgroundColor: '#BF0052'}} variant="contained" color="primary" onClick={(param) => this.changeVideo("video1", 0)}>Cook3rSIM configuratie</div>
	      			<div className="choiceBoxVid" variant="contained" color="primary" onClick={(param) => this.changeVideo("video2", 1)}>Cook3rSIM koppeling met prototype</div>
	      			<div className="choiceBoxVid" variant="contained" color="primary" onClick={(param) => this.changeVideo("video3", 2)}>Cook3rSIM simulatie kookproces</div>
	      			<div className="choiceBoxVid" variant="contained" color="primary" onClick={(param) => this.changeVideo("video4", 3)}>Cook3rSIM simulatie stap voor stap</div>
				</div>
				<div className="videoBody">
					<div id="video1" className="video"><h2>Cook3rSIM configuratie</h2>
						<iframe src="https://player.vimeo.com/video/883240690?h=d285a810c5" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
					</div>
					<div id="video2" className="video" style={{display: 'none'}}><h2>Cook3rSIM koppeling met prototype</h2>
						<iframe src="https://player.vimeo.com/video/878564569?h=411e49ffc5" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
					</div>
					<div id="video3" className="video" style={{display: 'none'}}><h2>Cook3rSIM simulatie kookproces</h2>
						<iframe src="https://player.vimeo.com/video/878564983?h=af49eaa34c" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
					</div>
					<div id="video4" className="video" style={{display: 'none'}}><h2>Cook3rSIM simulatie stap voor stap</h2>
						<iframe src="https://player.vimeo.com/video/878565003?h=dd38384f7f" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
					</div>
				</div>
      		</div>
    	)
  	};
}

export default VideoBlock;