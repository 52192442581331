import React from 'react';

//Change to real url
var targetUrl = "https://cook3r-backend.aimsites.nl/run_simulation";

const feedbackImages = require('../Feedback_run_simulation.json');

const mainIng = ["meat", "beans"];
const prepIng = ["water", "butter"];

class RunSimulation extends React.Component{

  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      logs: [],
      message: ""
    }

  }

  componentDidMount(){
    this.sendSettings(this.props.settings);
  }

  async sendSettings(settings){
    console.log(settings);

    //Send settings to API
    const response = await fetch(targetUrl, {
      method: 'POST',
      body: JSON.stringify(settings),
    });
    const results = await response.json();
    this.setState({
      message: results.message
    });
    console.log(results);

    if(results.predicted_actions_sequence){
      this.startLogging(results.predicted_actions_sequence);
    }
  }

  async startLogging(seq){
    console.log(seq);
    for(var i = 0; i < seq.length; i++){
      var currentLogs =  this.state.logs;
      currentLogs.push(seq[i]);
      this.setState({
        logs: currentLogs
      });
      console.log(this.state.logs);
      console.log(seq[i]);
      if(seq[i].action && document.getElementsByClassName("feedbackImage")[0] != undefined){
        if(seq[i].action.startsWith("temp")){
          if(seq[i].last_goal == true && seq[i].action.endsWith("audio")){
            document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[seq[i].action + "_last"]);
          }else{
            document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[seq[i].action]);
          }
        }else if (seq[i].action.startsWith("ing")){
          var currentGoal = JSON.parse(seq[i].current_goal.replace(/'/g, '"'));
          if(seq[i].last_goal == true && seq[i].action.endsWith("audio")){
            document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[seq[i].action + "_last"]);
          }else if(prepIng.includes(currentGoal.ing) && seq[i].action.endsWith("audio")){
            document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[seq[i].action + "_prep"]);
          }else if(mainIng.includes(currentGoal.ing) && seq[i].action.endsWith("audio")){
            document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[seq[i].action + "_main"]);
          }else{
            document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[seq[i].action]);
          }
        }else{
          document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[seq[i].action]);
        }
        await new Promise(res => setTimeout(res, 2000) );
      }
    }
  }

  render() {
    return (
      <div className="content">
        <div className="resultBox resultBoxPit">
          <div className="pit"><img class="feedbackImageBase" src={require(`../img/feedback/basis.png`)}></img><img class="feedbackImage" src=""></img></div>
        </div>
        <div className="resultBox resultBoxLog">
          <p>{this.state.message}</p>
          {this.state.logs.map((log, i) => (  
              <div>  
                <p className="bigLogs">{JSON.stringify(log)}</p>  
              </div>  
            ))}  
        </div>
      </div>
    );
  }
}

export default RunSimulation;
