import React from 'react';

import { Button }  from '@material-ui/core';

//Change to real url
var targetUrl = "https://cook3r-backend.aimsites.nl/run_live";
var targetUrl2 = "https://cook3r-backend.aimsites.nl/run_single";

//The data
var products = [];
const context = require.context('../products', true, /.json$/);
  context.keys().forEach((key: any) => {
    const fileName = key.replace('./', '');
    const resource = require(`../products/${fileName}`);
    const namespace = fileName.replace('.json', '');
    products.push(JSON.parse(JSON.stringify(resource)));
});

const feedbackImages = require('../Feedback_run_single.json');

const mainIng = ["meat", "beans"];
const prepIng = ["water", "butter"];

class RunSingle extends React.Component{

  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      logs: [],
      message: "",
      env_id: "",
      model_id: "",
      current_state: {
        "panstate": "",
        "pit_id": "",
        "temp": "",
        "ing": "",
      },
      state: {
        
      },
      allowed_states: {

      },
      step: {

      }
    }

    this.sendStep = this.sendStep.bind(this);
    this.buildStateContent = this.buildStateContent.bind(this);
    this.addState = this.addState.bind(this);
  }

  componentDidMount(){
    this.sendSettings(this.props.settings);
  }

  async sendSettings(settings){
    console.log(settings);

    //Send settings to API
    const response = await fetch(targetUrl, {
      method: 'POST',
      body: JSON.stringify(settings),
    });
    const results = await response.json();
    this.setState({
      message: results.message
    });
    console.log(results);
    this.setState({
      env_id: results.env_id
    });
    this.setState({
      model_id: results.model_id
    });

    this.setState({
      current_state: results.current_state
    });
    this.state.currentState = results.currentState;

    this.setState({
      allowed_states: results.allowed_states
    });
    console.log(this.state);
  }

  async sendStep(){

    let fullStep = {};
    fullStep["env_id"] = this.state.env_id;
    fullStep["model_id"] = this.state.model_id;
    fullStep["currentState"] = this.state.currentState;
    fullStep["state"] = this.state.state;

    //Send settings to API
    const response = await fetch(targetUrl2, {
      method: 'POST',
      body: JSON.stringify(fullStep),
    });
    const results = await response.json();
    this.setState({
      message: results.message
    });
    if(!results.message){
      this.setState({
        step: results
      });
      console.log(this.state.step);
      if(this.state.step.action && document.getElementsByClassName("feedbackImage")[0] != undefined){
        if(this.state.step.action.startsWith("temp")){
          if(this.state.step.last_goal == true && this.state.step.action.endsWith("audio")){
            document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[this.state.step.action + "_last"]);
          }else{
            document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[this.state.step.action]);
          }
        }else if (this.state.step.action.startsWith("ing")){
          // var currentGoal = JSON.parse(this.state.step.current_goal.replace(/'/g, '"'));
          var currentGoal = this.state.step.current_goal;
          if(this.state.step.last_goal == true && this.state.step.action.endsWith("audio")){
            document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[this.state.step.action  + "_last"]);
          }else if(prepIng.includes(currentGoal.ing) && this.state.step.action.endsWith("audio")){
            document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[this.state.step.action + "_prep"]);
          }else if(mainIng.includes(currentGoal.ing) && this.state.step.action.endsWith("audio")){
            document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[this.state.step.action + "_main"]);
          }else{
            document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[this.state.step.action]);
          }
        }else{
          document.getElementsByClassName("feedbackImage")[0].src = require("../img/feedback/" + feedbackImages[this.state.step.action]);
        }
      }
    }
    console.log(results);
  }

  buildStateContent(){
    let content = [];
    let fullSelect = [];
    // console.log(Object.keys(this.state.allowed_states).length);
    if(this.state.allowed_states && Object.keys(this.state.allowed_states).length > 0){
      console.log(Object.keys(this.state.allowed_states));
      Object.keys(this.state.allowed_states).forEach(state => {
        let selectOptionsTemp = [];
        console.log(state);
        this.state.allowed_states[state].forEach(s => {
          selectOptionsTemp.push(<option>{s}</option>);
        })
        fullSelect.push(<div class="startRow"><p>{state}</p><select value={this.state.state[state]} onChange={e => this.addState(state, e.target.value)}><option selected disabled>Kies</option>{selectOptionsTemp}</select></div>);
        
      })
      content.push(<div class="listItem listItemSelect boxMargin">{fullSelect}<Button variant="contained" color="primary" onClick={this.sendStep}>Run Single step</Button></div>);
    }
    return content;
  }

  addState(field, value){
    var currentState =  this.state.state;
    currentState[field] = value;
    this.setState({
      state: currentState
    });
  }

  render() {
    return (
      <div className="content">
        <div className="resultBox resultBoxPit">
          <div className="pit"><img class="feedbackImageBase" src={require(`../img/feedback/basis.png`)}></img><img class="feedbackImage" src=""></img></div><p class="feedbackText"></p>
        </div>
        {this.buildStateContent()}
        <div className="resultBox resultBoxLog">
          <p>Env_id: {this.state.env_id}</p>
          <p>Model_id: {this.state.model_id}</p>
          <p>Possible states: {this.state.current_state != undefined && Object.keys(this.state.current_state)}</p>
          <p>{this.state.message}</p>
          <h5 class="innerHeader">Latest step:</h5>
          <p>Action: {this.state.step.action}</p>
          <p>Current goal: {JSON.stringify(this.state.step.current_goal)}</p>
          <p>Previous goal: {JSON.stringify(this.state.step.previous_state)}</p> 
        </div>
      </div>
    );
  }
}

export default RunSingle;
