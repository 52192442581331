import React from 'react';
import logo from './logo.svg';
import './App.css';

import { Route, HashRouter, Routes, NavLink } from "react-router-dom";
import { Button }  from '@material-ui/core';

import Header from './components/Header.js';
import Footer from './components/Footer.js';
import VideoBlock from './components/VideoBlock.js';
import TrainModel from "./pages/TrainModel.js";
import RunSingle from "./pages/RunSingle.js";
import RunSimulation from "./pages/RunSimulation.js";
import Tabs from "./pages/Tabs";

import Placeholder from './img/placeholder.png';

//The data
var products = [];
const context = require.context('./products', true, /.json$/);
  context.keys().forEach((key: any) => {
    const fileName = key.replace('./', '');
    const resource = require(`./products/${fileName}`);
    const namespace = fileName.replace('.json', '');
    products.push(JSON.parse(JSON.stringify(resource)));
});
var states = [];
var actions = [];

var defaultPersonas = {};

class App extends React.Component{

  constructor(props) {
    super(props);

    this.buildProductContent = this.buildProductContent.bind(this);
    this.buildPersonaContent = this.buildPersonaContent.bind(this);
    this.buildStateContent = this.buildStateContent.bind(this);
    this.buildActionContent = this.buildActionContent.bind(this);
    this.buildGoalContent = this.buildGoalContent.bind(this);
    this.buildStartContent = this.buildStartContent.bind(this);
    this.buildStateActionContent = this.buildStateActionContent.bind(this);
    this.buildParametersContent = this.buildParametersContent.bind(this);

    this.addProduct = this.addProduct.bind(this);
    this.addPersona = this.addPersona.bind(this);
    this.addState = this.addState.bind(this);
    this.addAction = this.addAction.bind(this);
    this.addStart = this.addStart.bind(this);
    this.addGoal = this.addGoal.bind(this);
    this.addStateAction = this.addStateAction.bind(this);
    this.addParameter = this.addParameter.bind(this);

    this.showSettings = this.showSettings.bind(this);
    this.showToast = this.showToast.bind(this);
    this.checkTrain = this.checkTrain.bind(this);
    this.checkCompatibility = this.checkCompatibility.bind(this);

    this.updateProduct = this.updateProduct.bind(this);
    this.updateAction = this.updateAction.bind(this);
    this.updateState = this.updateState.bind(this);
    this.updateStateActions = this.updateStateActions.bind(this);
    this.updateGoals = this.updateGoals.bind(this);
    this.updateCueTypes = this.updateCueTypes.bind(this);

    this.downloadPersonaFile = this.downloadPersonaFile.bind(this);
    this.downloadProductFile = this.downloadProductFile.bind(this);
    this.handlePersonaUpload = this.handlePersonaUpload.bind(this);
    this.handleProductUpload = this.handleProductUpload.bind(this);
    this.handlePersonaSelection = this.handlePersonaSelection.bind(this);
    this.handleRunSimulationUpload = this.handleRunSimulationUpload.bind(this);
    this.handleRunSingleUpload = this.handleRunSingleUpload.bind(this);

    this.deletePersona = this.deletePersona.bind(this);
    this.deleteGoalseq = this.deleteGoalseq.bind(this);

    this.toggleOptions = this.toggleOptions.bind(this);
    this.reload = this.reload.bind(this);

    const context = require.context('./personas', true, /.json$/);
      context.keys().forEach((key: any) => {
        const fileName = key.replace('./', '');
        const resource = require(`./personas/${fileName}`);
        const namespace = fileName.replace('.json', '');
        defaultPersonas[namespace] = JSON.parse(JSON.stringify(resource));
    });

    this.state = {
      personas: [],
      product: {},
      progress: {
        disabledtab1: false,
        disabledtab2: true,
        disabledtab3: true,
        disabledtab4: true,
        disabledtab5: true,
        disabledtab6: true,
        disabledtab7: true,
        disabledtab8: true,
      },
      toast: "",
      runSimulationSettings: {
        "model_file":".zip",
        "config_file":".pkl"
      },
      runSingleSettings: {
        "model_file":".zip",
        "config_file":".pkl"
      }
    }
  }

  addProduct(productId, productName, box){
    var selected = products.find(p => p.id === productId && p.name === productName);
    console.log(selected)
    if(selected != undefined){
      if(box.checked == true){
        this.state.product = selected;
        this.setState({
          product: selected
        });
        console.log(this.state.product);
        states = this.state.product.options.states;
        actions = this.state.product.options.actions;
        this.state.personas = [];
      }else{
        this.state.product = {};
        this.state.personas = [];
      }
    }
    
    var currentProgress =  this.state.progress;
    var currentProgress =  this.state.progress;
    this.state.progress.disabledtab2 = false;
    this.state.progress.disabledtab3 = false;
    this.state.progress.disabledtab4 = false;
    this.state.progress.disabledtab5 = false;
    this.state.progress.disabledtab6 = false;
    this.state.progress.disabledtab7 = false;
    this.state.progress.disabledtab8 = false;
    this.setState({
      progress: currentProgress
    });
    this.showSettings();
  }

  addPersona(field, value, index){
    var currentSettings =  this.state.personas;
    currentSettings[index][field] = parseInt(value);
    // if(field == "dementia_stage" && this.state.product != undefined && Object.keys(this.state.product).length != 0){
    //   this.state.product.settings.actions = ["wait"];
    //   this.state.product.settings.states = {};
    //   this.state.product.settings.state_actions = {wait: []};

    //   for(var i = 0; i < document.getElementsByClassName("actionCheckbox").length; i++){
    //     document.getElementsByClassName("actionCheckbox")[i].checked = false;
    //   }

    // }
    this.setState({
      persona: currentSettings
    });
    this.showSettings();
  }

  addParameter(field, value){
    var currentSettings =  this.state.product.settings;
    if(field == "model"){
      currentSettings["model"]["modeltype"] = value;
    }else{
      currentSettings[field] = parseInt(value);
    }
    this.setState({
      settings: currentSettings
    });
    var currentProgress =  this.state.progress;
    this.state.progress.disabledtab8 = false;
    this.setState({
      progress: currentProgress
    });
    this.showSettings();
  }

  addState(stateId, box){
    var selected = states.find(s => s.id === stateId);
    var currentSettings =  this.state.product.settings;
    if(box.checked == true){
      currentSettings.states[selected.id] = [];
      selected.states.forEach(state => {
        currentSettings.states[selected.id].push(state);
      })
    }else{
      delete currentSettings.states[selected.id];
    }

    this.setState({
      settings: currentSettings
    });

    var currentProgress =  this.state.progress;
    this.state.progress.disabledtab4 = false;
    this.setState({
      progress: currentProgress
    });
    this.showSettings();
  }

  addAction(actionId, box){
    console.log(actionId);
    var selected = this.state.product.options.actions.find(a => a.id === actionId);
    var currentSettings =  this.state.product.settings;
    if(box.checked == true){
      if(selected.id != "wait"){
          currentSettings.actions.action_types.push(selected.id);
      }else{
        currentSettings.actions.action_types.push(selected.id);
      }
      currentSettings.state_actions[selected.id] = [];
    }else if(actionId == "dementia_levels"){
      currentSettings.actions["dementia_levels"] = parseInt(box);
      var currentProgress =  this.state.progress;
      this.state.progress.disabledtab8 = false;
      this.setState({
        progress: currentProgress
      });
    }else if(actionId == "cue_types"){
      var selectedOptions = [];
    for(var i = 0; i < box.selectedOptions.length; i++){
      selectedOptions.push(box.selectedOptions[i].value);
    }
    currentSettings.actions["cue_types"] = selectedOptions;
    }else{
        var updatedActions = currentSettings.actions.action_types.filter(function(action) {
          return action != selected.id;
        });
        currentSettings.actions.action_types = updatedActions;
        delete currentSettings.state_actions[selected.id];
    }

    this.setState({
      settings: currentSettings
    });

    var currentProgress =  this.state.progress;
    this.state.progress.disabledtab3 = false;
    this.setState({
      progress: currentProgress
    });
    this.showSettings();
  }

  addStart(field, value){
    var currentSettings =  this.state.product.settings;
    currentSettings.start_state[field] = value;
    this.setState({
      settings: currentSettings
    });
    var currentProgress =  this.state.progress;
    this.state.progress.disabledtab7 = false;
    this.state.progress.disabledtab8 = false;
    this.setState({
      progress: currentProgress
    });
    this.showSettings();
  }

  addGoal(field, count, value, index){
    var currentSettings = this.state.product.settings;
    console.log(currentSettings.goals[index][count]);
    if(field == "multiple"){
      var selectedOptions = [];
      for(var i = 0; i < value.selectedOptions.length; i++){
        selectedOptions.push(value.selectedOptions[i].value);
      }
      currentSettings.goals[index][count][2] = selectedOptions;
    }else if(field == "fuzzy"){
      currentSettings.goals[index][count][3] = parseInt(value);
    }else if(field != ""){
      currentSettings.goals[index][count][0][field] = value;
    }else{
      currentSettings.goals[index][count][1] = parseInt(value);
    }
    
    this.setState({
      settings: currentSettings
    });

    var currentProgress =  this.state.progress;
    this.state.progress.disabledtab6 = false;
    this.setState({
      progress: currentProgress
    });
    this.showSettings();
  }

  showSettings(){
    let content = [];
    var settingsBox = document.querySelector("#settings");
    console.log(this.state.product.settings);
    content.push(<h4>Overzicht</h4>);
    content.push(<span>Product: {this.state.product.name}</span>);
    if(this.state.product != undefined && Object.keys(this.state.product).length != 0){
      content.push(<span>Actions: {JSON.stringify(this.state.product.settings.actions, null, 2)}</span>);
      content.push(<span>States: {JSON.stringify(this.state.product.settings.states, null, 2)}</span>);
      content.push(<span>State_Actions: {JSON.stringify(this.state.product.settings.state_actions, null, 2)}</span>);
      content.push(<span>Goals: {JSON.stringify(this.state.product.settings.goals, null, 2)}</span>);
      content.push(<span>Start: {JSON.stringify(this.state.product.settings.start_state, null, 2)}</span>);
      content.push(<span>N_envs: {JSON.stringify(this.state.product.settings.n_envs, null, 2)}</span>);
      content.push(<span>Model: {JSON.stringify(this.state.product.settings.model.modeltype, null, 2)}</span>);
    }
    content.push(<span>Personas: {JSON.stringify(this.state.personas, null, 2)}</span>);

    return (<div class="settingsBody">{content}</div>);
  }

  updateProduct(id){
    if(this.state.product.id == id){
      return true;
    }else{
      return false;
    }
  }

  updateAction(id){
    if(this.state.product.settings.actions.action_types.find(a => a === id)){
      return true;
    }else{
      return false;
    }
  }

  updateState(id){
    if(this.state.product.settings.states[id]){
      return true;
    }else{
      return false;
    }
  }

  updateStateActions(stateAction, state){
    if(this.state.product.settings.state_actions[stateAction].find(sa => sa === state)){
      return true;
    }else{
      return false;
    }
  }

  updateGoals(goals, stateAction, index){
    if(!Array.isArray(goals)){
      if(goals === stateAction){
        return true;
      }else{
        return false;
      }
    }
    if(goals.find(g => g === stateAction)){
      return true;
    }else{
      return false;
    }
  }

  updateCueTypes(cuetype){
    if(this.state.product.settings.actions.cue_types.find(ct => ct === cuetype)){
      return true;
    }else{
      return false;
    }
  }

  buildProductContent(){ 
    let content = [];
    products.forEach(product => {
      content.push(<div class="listItem"><label for={product.id}><input type="checkbox" checked={this.updateProduct(product.id)} class="productCheckbox" id={product.id} onChange={e => this.addProduct(product.id, product.name, e.target)}></input><div class="listLabel"><img src={require(`./img/${product.img}`)} /><span>{product.name}</span></div></label></div>);
    })
    content.push(<div class="uploadBox"><Button variant="contained" color="primary"><label for="uploadProduct">Upload Product</label></Button><input hidden id="uploadProduct" name="uploadProduct" type="file" onChange={this.handleProductUpload} /></div>);
    content.push(<div class="uploadBox"><Button variant="contained" color="secondairy" onClick={this.downloadProductFile}>Export product</Button></div>);
    return content;
  };

  buildParametersContent(){ 
    let content = [];
    var stateBox = document.querySelector("#parameters");
    if(this.state.product != undefined && Object.keys(this.state.product).length != 0){
      content.push(<div class="listItem listItemSelect"><p for="n_envs">Number of environments:</p><input id="n_envs" type="number" min="0" max="10" value={this.state.product.settings.n_envs} onChange={e => this.addParameter("n_envs", e.target.value)}></input><div class="listLabel alterSelect"><img src={require(`./img/20230110 COOK3R SIM interface-63.png`)} /></div></div>);
      let fullSelect = [];
      fullSelect.push(<p>Model:</p>);
      if(this.state.product != undefined && Object.keys(this.state.product).length != 0){
        let selectOptionsTemp = [];
        this.state.product.options.model.modeltypes.forEach(model => {
          selectOptionsTemp.push(<option>{model}</option>);
        })
        fullSelect.push(<select value={this.state.product.settings.model.modeltype} onChange={e => this.addParameter("model", e.target.value)}><option selected disabled>Kies</option>{selectOptionsTemp}</select>);
        content.push(<div class="listItem listItemSelect">{fullSelect}<div class="listLabel alterSelect"><img src={require(`./img/20230110 COOK3R SIM interface-64.png`)} /></div></div>);
      }
      return content;
    }
  };

  buildPersonaContent(){ 
    let content = [];
    var stateBox = document.querySelector("#personas");
    if(this.state.product != undefined && Object.keys(this.state.product).length != 0){
      this.state.personas.forEach((persona, index) => {
        let fields = [];
        fields.push(<div class="listItem listItemSelect"><p for="personaStage">Dementia stage:</p><input id="personaStage" type="number" min="1" max={this.state.product.settings.actions.dementia_levels} value={this.state.personas[index].dementia_stage} onChange={e => this.addPersona("dementia_stage", e.target.value, index)}></input><div class="personaImg"><img src={require(`./img/20221020 COOK3R SIM interface-03.png`)} /></div></div>);
        fields.push(<div class="listItem listItemSelect"><p for="personaConfusion">Confusion:</p><input id="personaConfusion" type="number" min="0" max="20" value={this.state.personas[index].confusion} onChange={e => this.addPersona("confusion", e.target.value, index)}></input><div class="personaImg"><img src={require(`./img/20221020 COOK3R SIM interface-05.png`)} /></div></div>);
        fields.push(<div class="listItem listItemSelect"><p for="personaAnnoyance">Annoyance:</p><input id="personaAnnoyance" type="number" min="0" max="100" value={this.state.personas[index].annoyance} onChange={e => this.addPersona("annoyance", e.target.value, index)}></input><div class="personaImg"><img src={require(`./img/20221020 COOK3R SIM interface-04.png`)} /></div></div>);
        fields.push(<div class="listItem listItemSelect"><p for="personaAudioResp">Audio response:</p><input id="personaAudioResp" type="number" min="0" max="100" value={this.state.personas[index].audio_resp} onChange={e => this.addPersona("audio_resp", e.target.value, index)}></input><div class="personaImg"><img src={require(`./img/20230110 COOK3R SIM interface-61.png`)}  /></div></div>);
        fields.push(<div class="listItem listItemSelect"><p for="personaVisualResp">Visual response:</p><input id="personaVisualResp" type="number" min="0" max="100" value={this.state.personas[index].visual_resp} onChange={e => this.addPersona("visual_resp", e.target.value, index)}></input><div class="personaImg"><img src={require(`./img/20230110 COOK3R SIM interface-62.png`)} /></div></div>);
        fields.push(<div class="uploadBox"><Button variant="contained" color="secondairy" onClick={e => this.downloadPersonaFile(index)}>Export persona</Button></div>);
        content.push(<div class="personaBox"><div class="personaHeader"><h2>Persona {index}</h2><span onClick={e => this.deletePersona(index)}>verwijderen</span></div>{fields}</div>);
      })
      content.push(<div class="uploadBox"><Button variant="contained" color="primary" id="addPersonaRow" onClick={e => this.addPersonaRow()} >Nieuw persona</Button></div>)
      content.push(<div class="uploadBox"><Button variant="contained" color="primary"><label for="uploadPersona">Upload persona</label></Button><input hidden id="uploadPersona" name="uploadPersona" type="file" onChange={this.handlePersonaUpload} /></div>);
      let selectOptions = [];
      selectOptions.push(<option disabled selected>Voeg bestaand persona toe</option>);
      Object.keys(defaultPersonas).forEach(p => {
        selectOptions.push(<option>{p}</option>);
      })
      content.push(<div class="uploadBox"><select onChange={this.handlePersonaSelection}>{selectOptions}</select></div>);
      return content;
    }
  };

  buildStateContent(){ 
    let content = [];
    var stateBox = document.querySelector("#states");
    if(this.state.product != undefined && Object.keys(this.state.product).length != 0){
      this.state.product.options.states.forEach(fullState => {
        if(fullState != undefined){
          content.push(<div class="listItem"><label for={fullState.id}><input type="checkbox" checked={this.updateState(fullState.id)} class="stateCheckbox" id={fullState.id} onChange={e => this.addState(fullState.id, e.target)}></input><div class="listLabel"><img src={require(`./img/${fullState.img}`)} /><span>{fullState.name}</span></div></label></div>);
        }
      })
    }
    return content;
  };

  buildActionContent(){ 
    let content = [];
    var actionBox = document.querySelector("#actions");
    if(this.state.product != undefined && Object.keys(this.state.product).length != 0){
      this.state.product.options.actions.forEach(fullAction => {
        if(fullAction != undefined){
          if(fullAction.setting == undefined){
            content.push(<div class="listItem"><label for={fullAction.id}><input type="checkbox" checked={this.updateAction(fullAction.id)} class="actionCheckbox" id={fullAction.id} onChange={e => this.addAction(fullAction.id, e.target)}></input><div class="listLabel"><img src={require(`./img/${fullAction.img}`)} /><span>{fullAction.name}</span></div></label></div>);
          }else if(fullAction.id == "dementia_levels"){
            content.push(<div class="listItem listItemSelect"><p for={fullAction.id}>{fullAction.name}</p><input id={fullAction.id} type="number" min="0" max="5" value={this.state.product.settings.actions.dementia_levels} onChange={e => this.addAction(fullAction.id, e.target.value)}></input><div class="listLabel alterSelect"><img src={require(`./img/${fullAction.img}`)} /></div></div>);
          }else if(fullAction.id == "cue_types"){
            let selectOptions = [];
            console.log(fullAction);
            fullAction.options.forEach(ct => {
              selectOptions.push(<option selected={this.updateCueTypes(ct)}>{ct}</option>);
            })
            content.push(<div class="listItem listItemSelect"><p for={fullAction.id}>{fullAction.name}</p><select multiple onChange={e => this.addAction(fullAction.id, e.target)}>{selectOptions}</select><div class="listLabel alterMulti"><img src={require(`./img/${fullAction.img}`)} /></div></div>);
          }else{
            content.push(<div class="listItem"><label for={fullAction.id}><input type="checkbox" checked={this.updateAction(fullAction.id)} class="actionCheckbox" id={fullAction.id} onChange={e => this.addAction(fullAction.id, e.target)}></input><div class="listLabel"><span>{fullAction.name}</span></div></label></div>);
          }
        }
      })
    }
    return content;
  };

  buildGoalContent(){ 
    //state, tijdsduur,lijst van toegestane acties
    let count = 0;
    let content = [];
    if(this.state.product != undefined && Object.keys(this.state.product).length != 0){
      this.state.product.settings.goals.forEach((goalseq, indexTop) => {
        let fields = [];
        goalseq.forEach((goal, indexBottom) => {
          let fullSelect = [];
          Object.keys(this.state.product.settings.states).forEach(state => {
            fullSelect.push(<span>{state}</span>);
            let selectOptionsTemp = [];
            this.state.product.settings.states[state].forEach(s => {
              selectOptionsTemp.push(<option selected={this.updateGoals(goal[0][state], s, indexTop)}>{s}</option>);
            })
            fullSelect.push(<select onChange={e => this.addGoal(state, indexBottom, e.target.value, indexTop)}><option selected disabled>Kies</option>{selectOptionsTemp}</select>);
          })
          let goalInput = [];
          
          goalInput.push(fullSelect);
          goalInput.push(<div class="goalBody"><span>Tijd:</span><input type="number" min="0" id={`goalTime${count}`} className="goalTime" value={goal[1]} onChange={e => this.addGoal("", indexBottom, e.target.value, indexTop)} /></div>);
          goalInput.push(<div class="goalBody"><span>Fuzzy:</span><input type="number" min="0" id={`goalFuzzy${count}`} className="goalFuzzyness" value={goal[3]} onChange={e => this.addGoal("fuzzy", indexBottom, e.target.value, indexTop)} /></div>);

          let fullSelectMultiple = [];
          let selectOptionsMultiple = [];
          Object.keys(this.state.product.settings.state_actions).forEach(stateAction => {
            selectOptionsMultiple.push(<option selected={this.updateGoals(goal[2], stateAction, indexTop)}>{stateAction}</option>);
          })
          goalInput.push(<select multiple onChange={e => this.addGoal("multiple", indexBottom, e.target, indexTop)}>{selectOptionsMultiple}</select>);
          fullSelectMultiple.push(<div class="goalBlock">{goalInput}</div>);
          
          fields.push(<div class="listItem">{fullSelectMultiple}</div>);
        })
        fields.push(<div class="uploadBox"><Button variant="contained" color="primary" id="addGoalRow" onClick={e => this.addGoalRow(indexTop)} >Goal toevoegen</Button></div>);
        content.push(<div class="goalBox"><div class="goalHeader"><h2>Goalsequence {indexTop}</h2><span onClick={e => this.deleteGoalseq(indexTop)}>verwijderen</span></div>{fields}</div>);
      })

      content.push(<div class="uploadBox"><Button variant="contained" color="primary" id="addGoalSeq" onClick={e => this.addGoalSeq()} >Nieuwe goalsequence</Button></div>)
    }
    return content;
  };

  addGoalSeq(){
    var currentSettings =  this.state.product.settings;
    currentSettings.goals.push([]);
    this.setState({
      settings: currentSettings
    });
  }

  addGoalRow(index){
    var currentSettings =  this.state.product.settings;
    currentSettings.goals[index].push([{}, 0, [], 0]);
    this.setState({
      settings: currentSettings
    });
  }

  addPersonaRow(){
    var currentSettings =  this.state.personas;
    currentSettings.push({});
    this.setState({
      settings: currentSettings
    });
  }

  buildStartContent(){ 
    let content = [];
    let fullSelect = [];
    if(this.state.product != undefined && Object.keys(this.state.product).length != 0){
      Object.keys(this.state.product.settings.states).forEach(state => {
        let selectOptionsTemp = [];
        this.state.product.settings.states[state].forEach(s => {
          selectOptionsTemp.push(<option>{s}</option>);
        })
        fullSelect.push(<div class="startRow"><p>{state}</p><select value={this.state.product.settings.start_state[state]} onChange={e => this.addStart(state, e.target.value)}><option selected disabled>Kies</option>{selectOptionsTemp}</select></div>);
        
      })
      content.push(<div class="listItem listItemSelect">{fullSelect}</div>);
    }
    return content;
  };

  buildStateActionContent(){ 
    let content = [];
    var stateBox = document.querySelector("#stateActions");
    console.log(this.state.product);
    if(this.state.product != undefined && Object.keys(this.state.product).length != 0 && this.state.product.settings.state_actions != undefined && Object.keys(this.state.product.settings.state_actions) != 0){
      Object.keys(this.state.product.settings.state_actions).forEach(stateAction => {
        let fullSelect = [];
        console.log(actions);
        var fullAction = this.state.product.options.actions.find(a => a.id === stateAction);
        fullSelect.push(<div class="listLabel listlabelCheck"><img src={require(`./img/${fullAction.img}`)} /><span>{stateAction}</span></div>);
        let selectOptions = [];
        Object.keys(this.state.product.settings.states).forEach(state => {
          selectOptions.push(<option selected={this.updateStateActions(stateAction, state)}>{state}</option>);
        })
        fullSelect.push(<select multiple onChange={e => this.addStateAction(stateAction, stateAction, e.target)}>{selectOptions}</select>);

        content.push(
          <div class="listItem">{fullSelect}</div>
        );
      })
    }
    return content;
  };

  addStateAction(action, state, value){
    var selectedOptions = [];
    for(var i = 0; i < value.selectedOptions.length; i++){
      selectedOptions.push(value.selectedOptions[i].value);
    }
    console.log(value.value);
    var currentSettings =  this.state.product.settings;
    currentSettings.state_actions[action] = selectedOptions;
    this.setState({
      settings: currentSettings
    });

    var currentProgress =  this.state.progress;
    this.state.progress.disabledtab5 = false;
    this.setState({
      progress: currentProgress
    });
    this.showSettings();
  }

  showToast(message){
    var x = document.getElementById("snackbar");
    this.setState({
      toast: message
    });
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }

  downloadPersonaFile(index){
   const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(this.state.persona[index], null, "\t")
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "persona.json";
    link.click();
 }

 downloadProductFile(){
  var date = Date.now();
  this.state.product.id = this.state.product.id + "_" + date;
    this.state.product.name = this.state.product.name + "_" + date;
   const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(this.state.product, null, "\t")
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "product.json";
    link.click();
 }

 checkCompatibility(productC, personaC){
  let check = true;
  if(personaC.dementia_stage > productC.settings.actions.dementia_levels){
    check = false;
  }
  for(var i = 0; i < productC.settings.actions.cue_types.length; i++){
    if(personaC[productC.settings.actions.cue_types[i] + "_resp"] == undefined){
      check = false;
    }
  }
  return check;
 }

 handlePersonaUpload(e){
  const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      var currentPersonas = this.state.personas;
      let newPersona = JSON.parse(e.target.result);
      let check = this.checkCompatibility(this.state.product, newPersona);
      if(check == true){
        currentPersonas.push(newPersona);
        this.setState({
          persona: currentPersonas
        });
        this.showToast("Persona geüpload!");
      }else{
        this.showToast("Persona niet compatibel met product!");
      }
    };
 }

 handleProductUpload(e){
  const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      var currentProduct =  this.state.product;
      currentProduct = JSON.parse(e.target.result);
      console.log(currentProduct);
      products.push(currentProduct);
      this.showToast("Product geüpload!");
    };
 }

 handlePersonaSelection(e){
  console.log(e.target.value);
  var currentPersonas =  this.state.personas;
  var newPersona = defaultPersonas[e.target.value];
  let check = true;
  if(newPersona.dementia_stage > this.state.product.settings.actions.dementia_levels){
    check = false;
  }
  for(var i = 0; i < this.state.product.settings.actions.cue_types.length; i++){
    if(newPersona[this.state.product.settings.actions.cue_types[i] + "_resp"] == undefined){
      check = false;
    }
  }
  if(check == true){
    currentPersonas.push(newPersona);
    this.setState({
      persona: currentPersonas
    });
    this.showToast("Persona toegevoegd!");
  }else{
    this.showToast("Persona niet compatibel met product!");
  }
  
 }

 checkTrain(e){ 
  if(this.state.product != undefined && Object.keys(this.state.product).length != 0 && this.state.personas != undefined && this.state.personas.length != 0){

  }else{
    e.preventDefault();
    this.showToast("Geen product of persona!");
  }
 }

 handleRunSimulationUpload(e){
  const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      let currentModel = this.state.runSimulationSettings;
      let model = JSON.parse(e.target.result);
      currentModel.model_file = model.model_file;
      currentModel.config_file = model.config_file;
      this.setState({
        runSimulationSettings: currentModel
      });
      console.log(this.state.runSimulationSettings);
    };
 }

 handleRunSingleUpload(e){
  const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      let currentModel = this.state.runSingleSettings;
      let model = JSON.parse(e.target.result);
      currentModel.model_file = model.model_file;
      currentModel.config_file = model.config_file;
      this.setState({
        runSingleSettings: currentModel
      });
      console.log(this.state.runSingleSettings);
    };
 }

 deletePersona(index){
  var currentPersonas =  this.state.personas;
  console.log(currentPersonas[index]);
  currentPersonas.splice(index, 1);
  this.setState({
    personas: currentPersonas
  });
 }

 deleteGoalseq(index){
  var currentSettings =  this.state.product;
  console.log(currentSettings.settings.goals[index]);
  currentSettings.settings.goals.splice(index, 1);
  this.setState({
    product: currentSettings
  });
  console.log(this.state.product);
 }

 toggleOptions(){
  console.log("Options");
  document.getElementsByClassName("videoBlock")[0].style.display = "none";
  document.getElementsByClassName("optionsBlock")[0].style.display = "none";
  document.getElementsByClassName("content")[0].style.display = "flex";
 }

  reload(){
    window.location.reload();
 }

  render() {
    
    const tabs = [
  {
    label: "Product",
    Component: <div id="products">{this.buildProductContent()}</div>,
    disabled: this.state.progress.disabledtab1
  },
  {
    label: "Acties",
    Component: <div id="actions">{this.buildActionContent()}</div>,
    disabled: this.state.progress.disabledtab2
  },
  {
    label: "States",
    Component: <div id="states">{this.buildStateContent()}</div>,
    disabled: this.state.progress.disabledtab3
  },
  {
    label: "Koppeling (actions-states)",
    Component: <div id="states">{this.buildStateActionContent()}</div>,
    disabled: this.state.progress.disabledtab4
  },
  {
    label: "Goals",
    Component: <div id="states">{this.buildGoalContent()}</div>,
    disabled: this.state.progress.disabledtab5
  },
  {
    label: "Start",
    Component: <div id="states">{this.buildStartContent()}</div>,
    disabled: this.state.progress.disabledtab6
  },
  {
    label: "Parameters",
    Component: <div id="states">{this.buildParametersContent()}</div>,
    disabled: this.state.progress.disabledtab7
  },
  {
    label: "Persona",
    Component: <div id="personas">{this.buildPersonaContent()}</div>,
    disabled: this.state.progress.disabledtab8
  },
];
    return (
      <div className="App"> 
        <Header />

        <VideoBlock />

        <div className="optionsBlock">
          <div class="choiceBoxInterface" onClick={this.toggleOptions}>NAAR INTERFACE</div>
        </div>

        <HashRouter>
            <Routes>
              <Route exact path="/" element={
                <div className="content" style={{display: 'none'}}>
                  <NavLink to="trainmodel">
                    <div className="choiceBox">
                      <h3>Train model</h3>
                      <p>Train een model op basis van een template aan instellingen of een nieuwe set met instellingen. Het getrainde model is vervolgens op te slaan.</p>
                      <img src={require(`./img/20230110 COOK3R SIM interface-67.png`)} />
                    </div>
                  </NavLink>
                  <NavLink to="runsimulation">
                    <div className="choiceBox">
                      <h3>Run simulation</h3>
                      <p>Draai een complete simulatie op basis van een getraind model. Bepaal de startstate. De resulstaten worden aan het eind weergegeven.</p>
                      <img src={require(`./img/20230110 COOK3R SIM interface-68.png`)} />
                    </div>
                  </NavLink>
                  <NavLink to="runsingle">
                    <div className="choiceBox">
                      <h3>Run single</h3>
                      <p>Draai stap-voor-stap een simulatie op basis van een getraind model. Bepaal de startstate en vervolgens elke tussenstap. De resultaten worden steeds weergegeven.</p>
                      <img src={require(`./img/20230110 COOK3R SIM interface-69.png`)} />
                    </div>
                  </NavLink>
                  <div className="optionsBlock">
                    <div class="choiceBoxInterface" onClick={this.reload}>TERUG</div>
                  </div>
                </div>

              } />
              <Route path="/trainmodel" element={
                <div className="App"> 
                  <div className="content">
                    <Tabs tabs={tabs} />
                    <div id="settings" className="box">
                      {this.showSettings()}
                      <NavLink to="results" onClick={this.checkTrain}>
                        <Button variant="contained" color="primary">
                          Train Model
                        </Button>
                      </NavLink>
                    </div>
                  </div>
                </div>} />
              <Route path="/runsimulation" element={
                <div className="content">
                  <div className="resultBox">
                    <h3>Run Simulation</h3>
                    <p>Upload model file:</p><input type="file" onChange={this.handleRunSimulationUpload} />
                    <NavLink to="results">
                      <Button variant="contained" color="primary">
                        Run Simulation
                      </Button>
                    </NavLink>  
                  </div>
                </div>} />
              <Route path="/runsingle" element={
                <div className="content">
                  <div className="resultBox">
                    <h3>Run Single</h3>
                    <p>Upload model file:</p><input type="file" onChange={this.handleRunSingleUpload} />
                    <NavLink to="results">
                      <Button variant="contained" color="primary">
                        Start
                      </Button>
                    </NavLink>  
                  </div>
                </div>} />

              <Route path="/trainmodel/results" element={<TrainModel settings={this.state.product.settings} personas={this.state.personas} />} />
              <Route path="/runsingle/results" element={<RunSingle settings={this.state.runSingleSettings} />} />
              <Route path="/runsimulation/results" element={<RunSimulation settings={this.state.runSimulationSettings} />} />
            </Routes>
            <Footer />
          </HashRouter>
          <div id="snackbar">{this.state.toast}</div>
          
      </div>
    );
  }
}

export default App;
